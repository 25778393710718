.phone_input {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  
    input {
      width: 300px;
      max-width: 80%;
      font-size: 18px !important;
      text-align: center;

      &::placeholder{
        opacity: 0.6;
        color: #9fafbe;
      }
    }

    &_error{
        display: flex;
        color: #f6566b;
    }
  }