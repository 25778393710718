.grid_square{
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.icon_with_label{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8fc1ff39;
    border-radius: 6px;
    overflow: hidden;
    padding: 4px 6px;
    width: max-content;
    height: max-content;

    &_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: large;
        margin-right: 4px;
    }

    & label{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: small;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: black;
        text-wrap: nowrap;
    }
}