/*--------------------------------------------------------------
#0.4	hero slider
--------------------------------------------------------------*/
.hero-slider {
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  z-index: 0;

  @include media-query(991px) {
    height: 600px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .swiper-slide {
    overflow: hidden;
    color: #fff;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .slide-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  // slider controls
  .swiper-button-prev,
  .swiper-button-next {
    background: rgba(255, 255, 255, 0.3);
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    text-align: center;
    border-radius: 60px;

    @include media-query(767px) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 25px;

    &:after {
      font-family: "themify";
      content: "\e629";
      font-size: 20px;
      color: #fff;
    }
  }

  .swiper-button-next {
    right: 25px;

    &:after {
      font-family: "themify";
      content: "\e628";
      font-size: 20px;
      color: #fff;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    text-align: center;
    line-height: 12px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 35px;
  }
}

/*--------------------------------------------------------------
	#hero-static
--------------------------------------------------------------*/
.hero-static {
  background-color: #e1f8fc;
  width: 100%;
  height: 780px;

  @include media-query(1199px) {
    height: 700px;
  }

  @include media-query(991px) {
    height: 500px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .container {
    height: 100%;
    display: table;
    position: relative;

    @include widther(992px) {
      padding-top: 100px;
    }

    .content {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .slide-inner {
    background-position: center top;
  }

  .slide-title,
  .slide-text {
    max-width: 540px;

    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .slide-btns {
    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  h2 {
    font-size: 65px;
    font-weight: 900;
    margin: 0.2em 0 0.42em;
    text-transform: capitalize;

    @include media-query(1199px) {
      font-size: 55px;
    }

    @include media-query(991px) {
      font-size: 45px;
    }

    @include media-query(767px) {
      font-size: 35px;
    }
  }

  p {
    font-size: 18px;

    font-weight: 600;
    margin: 0 0 2em;

    @include media-query(767px) {
      font-size: 16px;
    }
  }

  .slide-btns > a:first-child {
    margin-right: 20px;

    @include media-query(767px) {
      margin-right: 5px;
    }
  }

  .slide-offer {
    @include media-query(991px) {
      text-align: center;
    }

    > span {
      display: inline-block;
      background: $theme-primary-color;
      line-height: 32px;
      padding: 0 20px;
      font-size: 14px;
      color: $white;
      border-radius: 3px;
    }
  }

  .hero-pic {
    @include background-style(
      "../../images/hero-women.png",
      center center,
      100%,
      no-repeat,
      local
    );
    width: 760px;
    height: 630px;
    position: absolute;
    right: -100px;
    bottom: 0;
    z-index: -1;

    @include media-query(1199px) {
      width: 600px;
      height: 497px;
    }

    @include media-query(991px) {
      display: none;
    }
  }

  .hero-pic-animation {
    right: 0;
    opacity: 1;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
  }
}

/*--------------------------------------------------------------
	#hero-style-2
--------------------------------------------------------------*/
.hero-style-2 {
  height: 715px;

  @include media-query(1199px) {
    height: 700px;
  }

  @include media-query(991px) {
    height: 500px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .slide-title,
  .slide-text {
    max-width: 540px;
    background: #030c34af;
    border-radius: 8px;
    margin-top: 4px;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    backdrop-filter: blur(4px);

    @media screen and (max-width: 800px) {
      margin-bottom: 4px !important;
    }

    & p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0;
      padding: 8px 4px;
    }

    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .slide-btns {
    margin-top: 6px;

    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
      margin-top: 12px;
    }
  }

  h2 {
    font-size: 65px;
    font-weight: 900;
    margin: 0.2em 0 0.42em;
    color: $white;
    text-transform: capitalize;

    @include media-query(1199px) {
      font-size: 55px;
    }

    @include media-query(991px) {
      font-size: 45px;
    }

    @include media-query(767px) {
      font-size: 35px;
    }
  }

  p {
    font-size: 18px;

    font-weight: 600;
    margin: 0 0 2em;
    color: $white;

    @include media-query(767px) {
      font-size: 16px;
    }
  }

  .slide-btns > a:first-child {
    margin-right: 20px;

    @include media-query(767px) {
      margin-right: 5px;
    }
  }

  .slide-offer {
    @include media-query(991px) {
      text-align: start;
      margin-bottom: 6px;
    }

    > span {
      display: inline-block;
      background: $theme-primary-color;
      line-height: 32px;
      padding: 0 20px;
      font-size: 14px;
      color: $white;
      border-radius: 3px;
    }
  }
}

/*--------------------------------------------------------------
	#hero-static-s2
--------------------------------------------------------------*/
.hero-static-s2 {
  background-color: #f2fcfe;
  height: 700px;

  @include media-query(1199px) {
    height: 600px;
  }

  @include media-query(991px) {
    height: 450px;
  }

  @include media-query(767px) {
    height: 450px;
  }

  .container {
    height: 100%;
    display: table;
    position: relative;

    .content {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .slide-inner {
    background-position: center top;
  }

  .slide-title,
  .slide-text {
    max-width: 540px;

    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .slide-btns {
    @include media-query(991px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  h2 {
    font-size: 65px;
    font-weight: 900;
    margin: 0.2em 0 0.42em;
    text-transform: capitalize;

    @include media-query(1199px) {
      font-size: 55px;
    }

    @include media-query(991px) {
      font-size: 45px;
    }

    @include media-query(767px) {
      font-size: 35px;
    }
  }

  p {
    font-size: 18px;

    font-weight: 600;
    margin: 0 0 2em;

    @include media-query(767px) {
      font-size: 16px;
    }
  }

  .slide-btns > a:first-child {
    margin-right: 20px;

    @include media-query(767px) {
      margin-right: 5px;
    }
  }

  .slide-offer {
    @include media-query(991px) {
      text-align: center;
    }

    > span {
      display: inline-block;
      background: $theme-primary-color;
      line-height: 32px;
      padding: 0 20px;
      font-size: 14px;
      color: $white;
      border-radius: 3px;
    }
  }

  .hero-pic {
    @include background-style(
      "../../images/hero-pic-2.png",
      center center,
      100%,
      no-repeat,
      local
    );
    width: 776px;
    height: 566px;
    position: absolute;
    right: -200px;
    bottom: 0;

    @include media-query(1199px) {
      width: 600px;
      height: 438px;
    }

    @include media-query(991px) {
      display: none;
    }
  }
}
