/*--------------------------------------------------------------
	#home style 2
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#2.1	services-section-s2
--------------------------------------------------------------*/

.email-access {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  flex: 1;
  position: relative;
}

.services-section-s2 {
  padding-bottom: 60px;

  @include media-query(991px) {
    padding-bottom: 90px;
  }

  @include media-query(767px) {
    padding-bottom: 80px;
  }

  .service-grids {
    margin: 0 -30px;
  }

  .service-grids .grid {
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px 60px;
    padding: 0 15px;

    @include media-query(500px) {
      width: calc(100% - 30px);
      float: none;
      margin: 0 15px 50px;
    }
  }

  .icon .fi:before {
    font-size: 50px;
    color: $heading-color;
    margin-top: -0.2em;
    display: block;

    @include media-query(767px) {
      font-size: 40px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0.7em 0 1em;

    @include media-query(767px) {
      font-size: 18px;
    }

    a {
      color: $heading-color;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 1.5em;
  }

  .more {
    color: $theme-primary-color;
    text-decoration: underline;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }

  .contact-form {
    padding: 45px 35px 20px;
    border: 2px solid #b2d1fc;

    p {
      font-size: 15px;
      margin-bottom: 2em;
    }

    form > div {
      margin-bottom: 22px;
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      margin: 0 0 0.8em;
    }

    input,
    select {
      height: 42px;
      border: 1px solid #c3dcfc;
      box-shadow: none;
      border-radius: 0;
    }

    @include placeholder-style(#9ca3b0, 16px, normal);

    select {
      color: #132038;
      font-weight: 600;
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
    }

    .submit-area {
      margin-bottom: 0;
    }
  }
}

/*--------------------------------------------------------------
#2.2	about-section-s2
--------------------------------------------------------------*/
.about-section-s2 {
  padding-top: 0;

  .about-row > div {
    width: 50%;
    float: left;

    @include media-query(991px) {
      width: 100%;
      float: none;
    }
  }

  .about-row .details {
    background-color: transparentize($theme-primary-color, 0.97);
    display: table;
    padding: 50px 45px 60px;
    backdrop-filter: blur(2px);

    @include media-query(767px) {
      padding: 40px 25px 50px;
    }

    .middle {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .about-row .img-holder {
	@include media-query(980px) {
		height: 240px !important;
		align-items: center;
		justify-content: center;
		display: flex;
	  }
    @include media-query(767px) {
      height: 240px !important;
	  align-items: center;
	  justify-content: center;
	  display: flex;
    }

    img {
      width: 100%;
	  transform: scale(0.9);
      @include media-query(1199px) {
        min-height: 500px;
        object-fit: cover;
      }
      @include media-query(991px) {
        max-height: 80px;
        object-fit: scale-down;
        transform: scale(0.7);
      }

      @include media-query(800px) {
        max-height: 80px;
        object-fit: scale-down;
        transform: scale(0.7);
		align-items: center;
		justify-content: center;
		
      }
    }
  }

  .details h3 {
    font-size: 35px;
    font-weight: bold;
    margin: 0 0 0.6em;

    @include media-query(1199px) {
      font-size: 28px;
    }

    @include media-query(991px) {
      font-size: 25px;
    }

    @include media-query(767px) {
      font-size: 22px;
    }
  }

  .details p {
    margin-bottom: 2em;
  }

  .details ul li {
    font-weight: 600;

    i {
      font-size: 18px;

      color: $theme-primary-color;
      display: inline-block;
      padding-right: 10px;
      position: relative;
      top: 2px;
    }
  }

  .details ul > li + li {
    margin-top: 10px;
  }

  .about-row-2 {
    @include media-query(991px) {
      margin-top: 30px;
    }
  }
}

.service-locations {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

/*--------------------------------------------------------------
#2.3	testimonials-section-s2
--------------------------------------------------------------*/
.testimonials-section-s2 {
  @extend .testimonials-section;

  .owl-controls {
    padding-left: 0;
    text-align: center;
  }

  .slick-slider {
    margin: -15px;

    .slick-slide {
      padding: 15px;
    }
  }

  .slick-dots {
    text-align: center;
    padding: 0;
  }
}

.testimonials-pg-section-s2 {
  padding: 100px 0;

  @include media-query(991px) {
    padding: 90px 0;
  }

  @include media-query(767px) {
    padding: 80px 0;
  }
}
