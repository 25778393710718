.zip_code_input {
  max-width: 80%;
  font-size: 18px !important;
  text-align: center;
  margin-top: 16px;

  &::placeholder {
    opacity: 0.6;
    color: #9fafbe;
  }
}
