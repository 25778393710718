/*--------------------------------------------------------------
	#contact page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#5.1	contact-section
--------------------------------------------------------------*/
.contact-section {
	position: relative;
	padding-bottom: 65px;
	z-index: 1;

	.section-title {
		margin-bottom: 30px;
	}

	@include media-query(991px) {
		padding-bottom: 45px;
	}

	@include media-query(767px) {
		padding-bottom: 35px;
	}

	&:before {
		content: "";
		@include overlay(transparentize(#f5f7f9, 0.10));
		z-index: -1;
	}

	.content-area p {
		font-style: italic;
		text-align: center;
		padding: 0 45px;
		margin-bottom: 2.5em;
	}

	form input,
	form select,
	form textarea {
		background: transparent;
		width: 100%;
		height: 50px;
		border: 1px solid #c3dcfc;
		border-radius: 0;
		box-shadow: none !important;

		@include media-query(991px) {
			height: 45px;
		}

		&:focus {
			border-color: $theme-primary-color;
		}
	}

	form textarea {
		height: 140px;
	}

	form {
		margin: 0 -7.5px;
    	overflow: hidden;

		@include placeholder-style(#687188, 15px, normal);

	    select {
	        display: inline-block;
	        color: #a9a9a9;
	        cursor:pointer;
	        opacity: 1;
	        padding: 6px 12px;
	        font-size: 15px;
	    }
	}

	form select {
		color: #687188;
	}

	.form-field {
		margin-bottom: 30px;
	}

	form .fullwidth {
		width: calc(100% - 15px);
		float: none;
		clear: both;
	}

	.submit-area {
		width: 100%;
		float: none;
		clear: both;
		text-align: center;
		margin-top: 30px;
	}
}


/*--------------------------------------------------------------
#5.2	contact-info-section
--------------------------------------------------------------*/
.contact-info-section {
	padding-bottom: 70px;

	@include media-query(991px) {
		padding-bottom: 50px;
	}

	@include media-query(767px) {
		padding-bottom: 40px;
	}

	.contact-info {
		margin: 0 -15px 0;
	}

	.contact-info > div {
		width: calc(33.33% - 30px);
		float: left;
		position: relative;
		text-align: center;
		margin: 0 15px 40px;
		box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.15);
		padding: 45px;
	}

	@media (max-width: 991px) {
		.contact-info > div {
			width: calc(50% - 30px);
		}
	}

	@media (max-width: 767px) {
		.contact-info > div {
			width: calc(100% - 30px);
			float: none;
		}
	}

	.contact-info .icon {
		background: $theme-secondary-color;
		width: 80px;
		height: 80px;
		line-height: 80px;
		border-radius: 80px;
		text-align: center;
		margin: 0 auto 25px;
		display: table;
		-webkit-box-shadow: 0px 9px 57px 0px rgba(0, 0, 0, 0.13);
		box-shadow: 0px 9px 57px 0px rgba(0, 0, 0, 0.13);
		position: relative;

		&:before {
			content: "";
			background: transparentize($theme-secondary-color, 0.6);
			width: 100%;
			height: 100%;
			position: absolute;
			left: -7px;
			top: 0;
			border-radius: 50%;
			z-index: -1;
		}
	}

	.contact-info .icon i {
		font-size: 30px;
		font-size: 1.875rem;
		color: $heading-color;
		display: table-cell;
		vertical-align: middle;
	}

	@media (max-width: 767px) {
		.contact-info .icon i {
			font-size: 25px;
			font-size: 1.5625rem;
		}
	}

	.contact-info h5 {
		font-size: 22px;
		font-size: 1.375rem;
		font-weight: bold;
		margin: 0 0 0.5em;
	}

	.contact-info p {
		line-height: 1.5em;
		margin-bottom: 0.2em;
	}
}

.contact-section-s2 {
	background-image: none;
}

.contact-map-section {
	.contact-map {
		height: 450px;

		@include media-query(767px) {
			height: 350px;
		}
	}

	iframe {
		width: 100%;
		height: 100%;
		border: 0;
		outline: 0;
	}
}