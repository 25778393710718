.text_area_input {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 16px;

@media screen and (max-width: 1000px) {
  padding-top: 8px;
  padding-bottom: 8px;
}


  textarea {
    width: 340px;
    height: 120px;
    max-width: 90%;
    font-size: 18px !important;
    text-align: left;
    vertical-align: text-top;
    padding-left: 8px;

    &::placeholder{
      opacity: 0.6;
      color: #9fafbe;
    }
  }
}
