@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.price_calc_container {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 48px;

  @media screen and (max-width: 1000px) {
    padding-bottom: 6px;
  }
}

.price_calc {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 420px;
  width: 80%;
  max-width: 1200px;
  background: #92c3e5;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding: 5px 5px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: 520px;
    min-height: 520px;
    width: 400px;
    max-width: 94%;
  }

  &_left {
    display: flex;
    max-width: 410px;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-right: 5px;
    border-radius: 8px;
    overflow: hidden;
    pointer-events: none;
    user-select: none;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &_right {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 410px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;

    &_header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-height: 160px;
    }

    &_img {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      pointer-events: none;
      user-select: none;

      @media screen and (min-width: 1001px) {
        display: none;
      }
    }

    &_body {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    &_footer {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding-bottom: 8px;
    }
  }
}

.price_calc_body {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 4px 8px;

  &_submitted {
    @extend .price_calc_body;
    justify-content: space-evenly;
  }

  &_final {
    &_top {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto auto;
      flex-direction: row;
      flex: 2;
      width: 100%;
      min-height: 120px;
      height: 120px;
      align-items: center;
      padding-top: 12px;

      @media screen and (max-width: 1200px) {
        padding-top: 0px;
        padding-bottom: 6px;
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      height: 100%;
      width: 100%;
      flex-direction: column;
      height: 96px;
      padding-bottom: 12px;

      @media screen and (max-width: 1000px) {
        padding-top: 16px;
      }
    }
  }
}

.price_calc_footer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;

  &_vertical {
    @extend .price_calc_footer;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    flex: 2;
    min-height: 120px;
    margin-bottom: 12px;
  }

  &_button {
    background: yellow;
    color: #1a1749;
    border-radius: 30px;
    height: 54px;
    width: 220px;
    border: none;
    font-size: medium;
    font-weight: 600;
    letter-spacing: 1px;

    &:hover {
      background-color: #237cf5;
      color: white;
      text-decoration: none;
    }

    &:active {
      transform: translateY(2px);
    }

    @media screen and (max-width: 600px) {
      width: 180px;
      border-radius: 20px;
      max-width: 80%;
    }

    & label {
      pointer-events: none;
    }
  }

  &_button_blue {
    @extend .price_calc_footer_button;
    background-color: #237cf5;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;

    &_rect {
      @extend .price_calc_footer_button_blue;
      border-radius: 8px;

      &_icon {
        font-size: x-large;
        margin-right: 6px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(1.2);
      }
    }
  }

  &_rectangle_button {
    @extend .price_calc_footer_button_blue_rect;
    width: 200px;
    height: 54px;
    margin-left: 8px;
    margin-right: 8px;

    @media screen and (max-width: 1000px) {
      min-width: 140px;
    }

    & label {
      pointer-events: none;
    }

    &_submit {
      @extend .price_calc_footer_rectangle_button;
      background: #05d79c;
      &:hover {
        background: #05d79c;
        cursor: pointer;
        filter: brightness(1.2);
      }

      &_disabled {
        @extend .price_calc_footer_rectangle_button_submit;
        pointer-events: none;
        opacity: 0.4;
      }
    }

    &_disabled {
      @extend .price_calc_footer_rectangle_button;
      pointer-events: none;
      opacity: 0.4;

      &:hover {
        cursor: unset;
      }
    }
  }

  &_square_button {
    @extend .price_calc_footer_button_blue_rect;
    width: 64px;
    height: 64px;

    & label {
      pointer-events: none;
    }
  }

  &_grid_square {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 500px) {
      max-width: 180px;
    }

    @media screen and (max-width: 400px) {
      max-width: 170px;
    }
  }

  &_grid_6 {
    @extend .price_calc_footer_vertical;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    padding-left: 2%;
    padding-right: 2%;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &_grid_4 {
    @extend .price_calc_footer_vertical;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.price_calc_footer_loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price_calc_loading_bar {
  display: flex;
  width: 80%;
  height: 48px;
  background: #0f094167;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &_inner {
    display: flex;
    width: 100%;
    height: 100%;
    background: #5a8eff;
    transition: 0.1s;
  }

  &_label {
    display: flex;
    position: absolute;
    left: 48%;
    top: 25%;
    font-weight: 800;
    letter-spacing: 1px;
    color: white;
  }
}

.price_calc_footer_final {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  &_loading{
    position: absolute;
    bottom: 4%;
    background: #141440ba;
    min-width: 130px;
    border-radius: 16px;
    padding: 8px 16px;
    opacity: 1;
    z-index: 10;
    color: white;
    text-align: left;
    backdrop-filter: blur(2px);
  }

  &_box {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;

    &_estimate {
      display: flex;
      width: 200px;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      background: #8fc1ff3d;
      text-align: left;
      padding-left: 4%;
      padding-right: 4%;
      position: relative;

      @media screen and (max-width: 600px) {
        width: 130px;
      }

      & label {
        font-weight: 600;
        font-size: small;
        text-align: left;

        @media screen and (max-width: 600px) {
          font-size: x-small;
        }
      }

      & label:nth-child(2) {
        margin-left: 6px;
        font-size: x-large;
        font-weight: 700;
        color: #5e95ff;
      }

      &_discount {
        display: none;
        position: absolute;
        bottom: 4px;
        font-size: small;
        & label {
          font-size: small;
          margin-left: 4px;
        }
      }
    }
  }
}
