/******************************
	#page title
******************************/
.page-title {
	@include background-style("../../images/page-title-bg.png", center center, cover, no-repeat, local);
	width: 100%;
	height: 320px;
	position: relative;
	text-align: center;

	@include media-query(991px) {
		height: 120px;
	}

	&:before {
		content: "";
		@include overlay(#e1f8fc);
		z-index: -1;
	}

	.container {
		height: 100%;
		display: table;

		@include widther(992px) {
			padding-top: 100px;
		}

		> .row {
			vertical-align: middle;
			display: table-cell;
		}
	}


	h2 {
		font-size: 55px;
		font-weight: 900;
		margin: 0.2em 0 0.30em;
		text-transform: uppercase;

		@include media-query(1199px) {
			font-size: 40px;
		}
		@include media-query(991px) {
			font-size: 40px;
		}

		@include media-query(767px) {
			font-size: 25px;
		}
	}
}